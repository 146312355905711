const L = require("leaflet");
module.exports = function (APP, $) {
	var module = {};
	const L = require('leaflet');
	
	APP.map = {
		
		init: function() {
			
			const $maps = $('.leaflet-map');
			if(!$maps.length)
				return;
			
			$maps.each(function() {
				const cfg = $(this).data('map');
				if(!cfg)
					return console.error("Missing config values");
				
				var map = L.map(this, {
					center: [51.505, -0.09],
				}).setView([cfg.mapLat, cfg.mapLng], cfg.zoom);
				
				var tiles = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
					maxZoom: 18,
					attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
						'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
					id: 'mapbox/streets-v11',
					tileSize: 512,
					zoomOffset: -1
				}).addTo(map);
				
				var markerIcon = L.icon({
					iconUrl: '/images/map-marker.png',
					iconSize: [30, 46],
					iconAnchor: [15, 44],
					popupAnchor: [0, -46],
					// shadowUrl: 'my-icon-shadow.png',
					// shadowSize: [68, 95],
					// shadowAnchor: [22, 94]
				});
				
				var marker = L.marker([cfg.markerLat, cfg.markerLng], {
					icon: markerIcon
				}).addTo(map);
				marker.bindPopup(cfg.markerTitle).openPopup();
			})
		}
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.map.init();
	});
	
	return module;
};
