
// Container to pass variables from templates
var APP = document.APP = window.APP = {};

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/public.scss');

// Loads the jquery package from node_modules
// $ = global.$ = global.jQuery = require('jquery');
// require("expose-loader?$!jquery");
import $ from "jquery";

// Symfony Front Editor
// require('../../vendor/loicpennamen/sf-front-editor/sf-front-editor');

// Autosize textarea
const autosize = require('../../node_modules/autosize/dist/autosize.min');

// Bootstrap
const bootstrap = require('bootstrap');
require('./bootstrap-handler')(APP, bootstrap, $);

// vendors
const throttle = require('lodash/throttle');
require('jquery-parallax.js');

// App
require('./waves')(APP, $);
require('./public-flashbags')(APP, $, bootstrap);
require('./page-block-contact-map-public')(APP, $);

// Page blocks
require('./page-block-jumbo-public')($);
require('./page-block-contact-public')(APP, $);


// On page init
$(document).ready(function() {

	// Autosize
	var $autosizeElements = $('textarea');
	autosize($autosizeElements);

	// Debug autosize on hidden TextAreas
	$autosizeElements.on('focus', function(){
		autosize.update($(this));
	});

	// Menu
	const $menu = $('#main_menu_wrapper');
	const $body = $('body');
	const $window = $(window);
	window.addEventListener('scroll', throttle(function() {
		$body.toggleClass('sticky-menu', $menu.offset().top <= $window.scrollTop())
	}, 200));
	$body.toggleClass('sticky-menu', $menu.offset().top <= $window.scrollTop());
	
	$body.on('click', '[data-action="toggleMenu"]', function(){
		$body.toggleClass('menu-open');
	})
	
	// Subnav menu on touch device
	$('#main_nav .menu-item.level-0 a.level-0').on('touchstart', function(e){
		const $level0Wrapper = $(this).parent();
		const $level1Wrapper = $level0Wrapper.find('.level-1-wrapper');
		// Selon taille écran
		if(window.innerWidth < 992)
			return;
		// Si sous-categorie
		if($level1Wrapper.length <= 0)
			return;
		// Gestion show/hide
		$('#main_nav .menu-item.level-0').not($level0Wrapper).removeClass('touched');
		$level0Wrapper.toggleClass('touched');
		e.preventDefault();
	});
	
	// Custom Parallax
	$('.banner').each(function(i){
		const $container = $(this);
		const $parallaxElt = $container.find(' > *:first-child');
		
		$parallaxElt.parallax({
			imageSrc: $parallaxElt.data('image-src'),
			speed: $parallaxElt.data('speed'),
			zIndex: $parallaxElt.data('z-index'),
		});
		
		// Special effects
		const $mirror = $('body > .parallax-mirror').first();
		if($container.hasClass('separation-banner'))
			$mirror.addClass('separation-banner-mirror');
	});
	
	// Hash to BS tab
	if(window.location.hash) {
		const hash = window.location.hash.substr(1);
		$('.nav-link[data-hash="' + hash + '"]').trigger('click');
	}
	
	// Click hash in menu from same page
	// TODO : click depuis le menu ne change pas les tabs (normal)
	
	
	
});
