module.exports = function ($) {
	var module = {};
	
	const inst = {
		intervalDuration: 5000,
		
		init: function() {
			$('.cms-page-block[data-type="jumbo"]').each(function() {
				inst.initJumbo(this);
			})
		},
		
		initJumbo: function(jumboElt) {
			const $jumbo = $(jumboElt);
			const $slidesWrapper = $jumbo.find('.jumbo-slides');
			
			jumboElt.jumbo = {
				$navArrows: $jumbo.find('.jumbo-nav-arrows').children(),
				$navDots: $jumbo.find('.jumbo-nav-dots').children(),
				$slidesWrapper: $slidesWrapper,
				$slides: $slidesWrapper.children(),
				length: $slidesWrapper.children().length,
				n: 0,
				interval: setInterval(function(){
					inst.next(jumboElt);
				}, inst.intervalDuration),
			};
			
			// Arrows nav
			jumboElt.jumbo.$navArrows.each(function() {
				$(this).on('click', function(){
					inst.stopInterval(jumboElt);
					let n = jumboElt.jumbo.n;
					n += $(this).is(':first-child') ? -1 : 1;
					if(n < 0)
						n = jumboElt.jumbo.length -1;
					if(n >= jumboElt.jumbo.length)
						n = 0;
					
					jumboElt.jumbo.n = n;
					inst.update(jumboElt);
				})
			});
			
			// Dots nav
			jumboElt.jumbo.$navDots.each(function() {
				$(this).on('click', function(){
					inst.stopInterval(jumboElt);
					jumboElt.jumbo.n = $(this).index();
					inst.update(jumboElt);
				})
			})
			
			// First init
			inst.update(jumboElt);
		},
		
		update: function(jumboElt) {
			const n = jumboElt.jumbo.n;
			jumboElt.jumbo.$slidesWrapper.css('right', (n*100)+'%');
			jumboElt.jumbo.$navDots.eq(n).addClass('active').siblings().removeClass('active');
			jumboElt.jumbo.$slides.each(function() {
				$(this).toggleClass('active', n === $(this).index());
			})
		},

		next: function(jumboElt) {
			const n = jumboElt.jumbo.n;
			let newN = n+1;
			if(newN >= jumboElt.jumbo.$slides.length)
				newN = 0;
			jumboElt.jumbo.n = newN;
			inst.update(jumboElt);
		},

		stopInterval: function(jumboElt) {
			clearTimeout(jumboElt.jumbo.interval);
		}
		
	};
	
	// Init on ready
	$(document).ready(function () {
		inst.init();
	});
	
	return module;
};
