module.exports = function (APP, $, bootstrap) {
	var module = {};
	
	APP.publicFlashbags = {
        messages: [],

		display: function() {
            if(this.messages.length < 1)
                return;

            for (let i = 0; i < this.messages.length; i++) {
                const row = this.messages[i];

                let $tpl = this.getTemplate();
                $tpl.find('.modal-body').addClass('text-'+row.bsKey).html(row.message);
                $tpl.find('button:last').addClass('btn-'+row.bsKey);

                var myModal = new bootstrap.Modal($tpl, {

                });
                myModal.show();
            }
		},

        add: function(bsKey, message) {
            this.messages.push({
                bsKey: bsKey,
                message: message,
            })
        },

        getTemplate: function() {
            return $(
                '    <div class="modal" tabindex="-1">\n' +
                '        <div class="modal-dialog">\n' +
                '            <div class="modal-content">\n' +
                '                <div class="modal-body"></div>\n' +
                '                <div class="modal-footer">\n' +
                '                    <button type="button" class="btn" data-bs-dismiss="modal">Ok</button>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </div>\n' +
                '    </div>');
        }
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.publicFlashbags.display();
	});
	
	return module;
};
