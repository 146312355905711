module.exports = function (APP, $, bootstrap) {
	var module = {};
	
	APP.contactFormBlock = {
		init: function() {
			const $block = $('.cms-page-block[data-type="contact-form"]');
			if($block.length === 0)
				return;
			
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			
			// If context passed
			if(params.hasOwnProperty('context')) {
				const $form = $block.find('form').first();
				const $nameInput = $form.find('[name="message[fullname]"]').first();
				
				// Scroll
				$([document.documentElement, document.body]).animate({
					scrollTop: $form.offset().top -30
				}, 1000);
				
				// Focus on name
				setTimeout(function() {
					$nameInput.focus();
				}, 1000);
			}
        }
	};
	
	// Init on ready
	$(document).ready(function () {
		APP.contactFormBlock.init();
	});
	
	return module;
};
